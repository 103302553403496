// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cropper-view-box {
  border-radius: 50%;
}

.cropper-face {
  background-color: transparent !important;
  left: unset !important;
  top: unset !important;
}

.cropper-move {
  cursor: grab;
}

.cropper-container {
  width: 160px !important;
  height: 160px !important;
}
.cropper-container .cropper-wrap-box,
.cropper-container .cropper-canvas {
  margin: auto;
}

.cropper-crop-box {
  pointer-events: none;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.cropper-view-box {
  outline: none;
}

.cropper-center {
  display: none;
}

.cropper-modal {
  background-color: var(--gray-80);
}

.preview-heading {
  width: 206px;
  height: 28px;
}

.preview-wrap {
  width: 160px;
  height: 160px;
  overflow: hidden;
}

.preview-wrap img {
  width: auto;
  height: auto;
  max-width: none;
  max-height: none;
}

input[type=range]::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/components/layout/Header/components/Chat/components/ChangeProfilePopup/profilePopup.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,wCAAA;EACA,sBAAA;EACA,qBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,uBAAA;EACA,wBAAA;AACF;AACE;;EAEE,YAAA;AACJ;;AAGA;EACE,oBAAA;EAGA,mBAAA;EACA,oBAAA;EACA,2CAAA;AAFF;;AAKA;EACE,aAAA;AAFF;;AAKA;EACE,aAAA;AAFF;;AAKA;EACE,gCAAA;AAFF;;AAKA;EACE,YAAA;EACA,YAAA;AAFF;;AAKA;EACE,YAAA;EACA,aAAA;EACA,gBAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;AAFF;;AAOA;EACE,gBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,8BAAA;AAJF","sourcesContent":[".cropper-view-box {\n  border-radius: 50%;\n}\n\n.cropper-face {\n  background-color: transparent !important;\n  left: unset !important;\n  top: unset !important;\n}\n\n.cropper-move {\n  cursor: grab;\n}\n\n.cropper-container {\n  width: 160px !important;\n  height: 160px !important;\n\n  .cropper-wrap-box,\n  .cropper-canvas {\n    margin: auto;\n  }\n}\n\n.cropper-crop-box {\n  pointer-events: none;\n  // width: 124px !important;\n  // height: 124px !important;\n  top: 50% !important;\n  left: 50% !important;\n  transform: translate(-50%, -50%) !important;\n}\n\n.cropper-view-box {\n  outline: none;\n}\n\n.cropper-center {\n  display: none;\n}\n\n.cropper-modal {\n  background-color: var(--gray-80);\n}\n\n.preview-heading {\n  width: 206px;\n  height: 28px;\n}\n\n.preview-wrap {\n  width: 160px;\n  height: 160px;\n  overflow: hidden;\n}\n\n.preview-wrap img {\n  width: auto;\n  height: auto;\n  max-width: none;\n  max-height: none;\n  // margin: -20px 0 0 -20x;\n  // transform: scale(2.5);\n}\n\ninput[type=\"range\"]::-webkit-slider-thumb {\n  appearance: none;\n  width: 20px;\n  height: 20px;\n  border-radius: 20px;\n  background-color: var(--white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
