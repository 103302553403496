// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../ui-kit/Icons/svg/popup-bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-password-modal .modal-dialog {
  max-width: 540px !important;
}
.update-password-modal .modal-content {
  overflow: hidden;
}
.update-password-modal .modal-content .btn-close {
  background: var(--gray-60);
  opacity: 1;
  border-radius: 50%;
  border: 2px solid var(--gray-90);
}
.update-password-modal .modal-body {
  padding: 0 !important;
  overflow: hidden;
}
.update-password-modal .modal-body .popup-bg {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  min-height: 290px;
  height: 100%;
  width: 100%;
}
.update-password-modal .modal-body .popup-bg img {
  width: 185px;
  filter: drop-shadow(0 0px 15px #232222);
}
.update-password-modal .modal-body .popup-content {
  padding: 2.5rem;
}
.update-password-modal .modal-body .popup-content h2 {
  font-family: "Tungsten";
  color: var(--white);
  font-size: 42px;
  margin-bottom: 1rem;
}
.update-password-modal .modal-body .popup-content p {
  color: var(--gray-40);
  font-size: 1rem;
}
.update-password-modal .modal-body .popup-content p span {
  color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/components/layout/Popups/PasswordUpdatedPopup/passwordUpdated.scss"],"names":[],"mappings":"AACE;EACE,2BAAA;AAAJ;AAEE;EACA,gBAAA;AAAF;AACE;EACE,0BAAA;EACA,UAAA;EACA,kBAAA;EACA,gCAAA;AACJ;AAEE;EACE,qBAAA;EACA,gBAAA;AAAJ;AACI;EACE,yDAAA;EACA,sBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AACN;AAAO;EACC,YAAA;EACA,uCAAA;AAER;AACI;EACE,eAAA;AACN;AAAM;EACE,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;AAER;AAAM;EACE,qBAAA;EACA,eAAA;AAER;AADQ;EACE,mBAAA;AAGV","sourcesContent":[".update-password-modal {\n  .modal-dialog {\n    max-width: 540px !important;\n  }\n  .modal-content  {\n  overflow:hidden;\n  .btn-close {\n    background:var(--gray-60) ;\n    opacity: 1;\n    border-radius: 50%;\n    border: 2px solid var(--gray-90);\n  }\n  }\n  .modal-body {\n    padding: 0 !important;\n    overflow: hidden;\n    .popup-bg {\n      background-image: url(../../../ui-kit/Icons/svg/popup-bg.svg);\n      background-size: cover;\n      min-height: 290px;\n      height: 100%;\n      width: 100%;\n       img {\n        width: 185px;\n        filter: drop-shadow(0 0px 15px #232222);\n       }\n    }\n    .popup-content {\n      padding: 2.5rem;\n      h2 {\n        font-family: \"Tungsten\";\n        color: var(--white);\n        font-size: 42px;\n        margin-bottom: 1rem;\n      }\n      p {\n        color: var(--gray-40);\n        font-size: 1rem;\n        span {\n          color: var(--white);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
