// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.games-not-found {
  margin-top: 3rem;
}
.games-not-found span {
  background-color: var(--gray-90);
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  color: var(--gray-40);
  font-size: 18px;
  font-weight: 800;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/ContentNotFound/ContentNotFound.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAAC;EACC,gCAAA;EACA,oBAAA;EACA,qBAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AAEF","sourcesContent":[".games-not-found{\n  margin-top: 3rem;\n span{\n  background-color: var(--gray-90);\n  padding: 1.5rem 2rem;\n  border-radius: 0.5rem;\n  color: var(--gray-40);\n  font-size: 18px;\n  font-weight: 800;\n  font-style: normal;\n }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
