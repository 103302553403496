// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-card-slider .slick-list {
  margin: 0 -0.375rem;
}
.casino-card-slider .slick-track {
  padding: 1.1875rem 0 0;
}
@media screen and (max-width: 767.98px) {
  .casino-card-slider .slick-track {
    padding: 0.75rem 0 0;
  }
}
.casino-card-slider .slick-slide {
  height: auto;
}
.casino-card-slider .slick-slide > div {
  height: 100%;
}
.casino-card-slider-slide {
  padding: 0 0.375rem;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/CasinoCardSlider/index.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AAGE;EACE,sBAAA;AADJ;AAGI;EAHF;IAII,oBAAA;EAAJ;AACF;AAGE;EACE,YAAA;AADJ;AAGI;EACE,YAAA;AADN;AAKE;EACE,mBAAA;EACA,YAAA;AAHJ","sourcesContent":[".casino-card-slider {\n  .slick-list {\n    margin: 0 -0.375rem;\n  }\n\n  .slick-track {\n    padding: 1.1875rem 0 0;\n\n    @media screen and (max-width: 767.98px) {\n      padding: .75rem 0 0;\n    }\n  }\n\n  .slick-slide {\n    height: auto;\n\n    & > div {\n      height: 100%;\n    }\n  }\n\n  &-slide {\n    padding: 0 0.375rem;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
