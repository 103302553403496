// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verify-email .modal-dialog {
  max-width: 540px !important;
}
.verify-email .modal-content {
  overflow: hidden;
}
.verify-email .modal-content .btn-close {
  background: var(--gray-60);
  opacity: 1;
  border-radius: 50%;
  border: 2px solid var(--gray-90n);
}
.verify-email .modal-body {
  padding: 0 !important;
  overflow: hidden;
}
.verify-email .modal-body .popup-bg {
  position: relative;
  min-height: 219px;
  height: 100%;
  width: 100%;
}
.verify-email .modal-body .popup-bg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.verify-email .modal-body .popup-content {
  padding: 2.5rem;
}
@media (max-width: 767px) {
  .verify-email .modal-body .popup-content {
    padding: 1.5rem;
  }
}
.verify-email .modal-body .popup-content h2 {
  font-family: "Rubik", sans-serif;
  color: var(--white);
  font-size: 42px;
  margin-bottom: 1rem;
}
.verify-email .modal-body .popup-content p {
  color: var(--gray-20);
  font-size: 1rem;
}
.verify-email .modal-body .popup-content p span {
  color: var(--white);
}
.verify-email .modal-body .popup-content .popup-note {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/Popups/VerifyEmailPopup/verifyEmail.scss"],"names":[],"mappings":"AACE;EACE,2BAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAGI;EACE,0BAAA;EACA,UAAA;EACA,kBAAA;EACA,iCAAA;AADN;AAKE;EACE,qBAAA;EACA,gBAAA;AAHJ;AAKI;EACE,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AAHN;AAKM;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;AAHR;AAQI;EACE,eAAA;AANN;AAQM;EAHF;IAII,eAAA;EALN;AACF;AAOM;EACE,gCAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;AALR;AAQM;EACE,qBAAA;EACA,eAAA;AANR;AAQQ;EACE,mBAAA;AANV;AAUM;EACE,eAAA;AARR","sourcesContent":[".verify-email {\n  .modal-dialog {\n    max-width: 540px !important;\n  }\n\n  .modal-content {\n    overflow: hidden;\n\n    .btn-close {\n      background: var(--gray-60);\n      opacity: 1;\n      border-radius: 50%;\n      border: 2px solid var(--gray-90n);\n    }\n  }\n\n  .modal-body {\n    padding: 0 !important;\n    overflow: hidden;\n\n    .popup-bg {\n      position: relative;\n      min-height: 219px;\n      height: 100%;\n      width: 100%;\n\n      img {\n        position: absolute;\n        top: 0;\n        left: 0;\n        width: 100%;\n        height: 100%;\n        object-fit: cover;\n        object-position: center;\n        //filter: drop-shadow(0 0px 15px #232222);\n      }\n    }\n\n    .popup-content {\n      padding: 2.5rem;\n\n      @media (max-width: 767px) {\n        padding: 1.5rem;\n      }\n\n      h2 {\n        font-family: \"Rubik\", sans-serif;\n        color: var(--white);\n        font-size: 42px;\n        margin-bottom: 1rem;\n      }\n\n      p {\n        color: var(--gray-20);\n        font-size: 1rem;\n\n        span {\n          color: var(--white);\n        }\n      }\n\n      .popup-note {\n        font-size: 12px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
