// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 992px) {
  .acknowledgement-dialog {
    min-width: 660px;
  }
}
.acknowledgement-dialog .acknowledgement {
  background-image: url("https://nextgen-dev-storage.s3.amazonaws.com/assets/Icons/png/acknowledgement-icon.webp");
}
.acknowledgement-dialog .acknowledgement .acknowledgement-body {
  padding: 2.5rem !important;
}
@media screen and (max-width: 375px) {
  .acknowledgement-dialog .acknowledgement .acknowledgement-body {
    padding: 1.5rem !important;
  }
}
.acknowledgement-dialog .acknowledgement .acknowledgement-body .ack-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  max-width: 351px;
}
@media screen and (max-width: 575px) {
  .acknowledgement-dialog .acknowledgement .acknowledgement-body .ack-text {
    font-size: 14px;
  }
}
@media screen and (max-width: 575px) {
  .acknowledgement-dialog .acknowledgement .acknowledgement-body .ack-btn {
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Crypto/components/AcknowledgementPopup/Acknowledgement.scss"],"names":[],"mappings":"AAGE;EADF;IAEI,gBAAA;EADF;AACF;AAGE;EACE,gHAAA;AADJ;AAGI;EACE,0BAAA;AADN;AAGM;EAHF;IAII,0BAAA;EAAN;AACF;AAEM;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAAR;AAEQ;EANF;IAOI,eAAA;EACR;AACF;AAGQ;EADF;IAEI,eAAA;EAAR;AACF","sourcesContent":["@import \"../../../../assets/sass/env\";\n\n.acknowledgement-dialog {\n  @media screen and (min-width: 992px) {\n    min-width: 660px;\n  }\n\n  .acknowledgement {\n    background-image: url('#{$s3Path}/assets/Icons/png/acknowledgement-icon.webp');\n\n    .acknowledgement-body {\n      padding: 2.5rem !important;\n\n      @media screen and (max-width: 375px) {\n        padding: 1.5rem !important;\n      }\n\n      .ack-text {\n        font-size: 16px;\n        font-weight: 500;\n        line-height: 24px;\n        max-width: 351px;\n\n        @media screen and (max-width: 575px) {\n          font-size: 14px;\n        }\n      }\n\n      .ack-btn {\n        @media screen and (max-width: 575px) {\n          font-size: 14px;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
