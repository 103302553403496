// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referral-section .form-group {
  max-width: 100% !important;
  min-width: 600px;
}
@media screen and (max-width: 767.98px) {
  .referral-section .form-group {
    min-width: 100%;
  }
}
.referral-section .form-group input {
  min-width: 400px;
}
@media screen and (max-width: 767.98px) {
  .referral-section .form-group input {
    min-width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/AmountInput/AmountInput.scss"],"names":[],"mappings":"AACE;EACE,0BAAA;EACA,gBAAA;AAAJ;AACI;EAHF;IAII,eAAA;EAEJ;AACF;AADE;EACE,gBAAA;AAGJ;AAFI;EAFF;IAGI,eAAA;EAKJ;AACF","sourcesContent":[".referral-section{\n  .form-group{\n    max-width: 100% !important;\n    min-width: 600px;\n    @media screen and (max-width: 767.98px){\n      min-width: 100%;\n    }\n  & input{\n    min-width: 400px;\n    @media screen and (max-width: 767.98px){\n      min-width: 100%;\n    }\n  }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
