// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-loader {
  position: fixed;
  z-index: 999;
  background: var(--loaderBg);
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-loader svg {
  width: 25% !important;
}
@media screen and (max-width: 767.98px) {
  .main-loader svg {
    width: 80% !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/Loader/MainLoader/mainLoader.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,2BAAA;EACA,WAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,qBAAA;AACJ;AACI;EAHF;IAII,qBAAA;EAEJ;AACF","sourcesContent":[".main-loader{\n  position: fixed;\n  z-index: 999;\n  background: var(--loaderBg);\n  width: 100%;\n  top: 0;\n  left: 0;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  svg {\n    width:25% !important;\n\n    @media screen and (max-width: 767.98px){\n      width: 80% !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
